<template>
    <div class="system_staff_style">
        <div class="main-container">
            <CommonSidebarStaffVue />
            <ModalStaffEmploymentVue />
            <ModalScreenSettingVue />
            <ModalEditCopyStaffVue/>
            <main>
                <router-view></router-view>
            </main>
        </div>
    </div>
</template>

<script>
    import CommonSidebarStaffVue from '@/components/common/systemStaff/CommonSidebarStaff.vue'
    import ModalStaffEmploymentVue from '@/components/common/systemStaff/ModalStaffEmployment.vue'
    import ModalScreenSettingVue from '@/components/common/systemStaff/ModalScreenSetting.vue'
    import ModalEditCopyStaffVue from '@/components/common/systemStaff/ModalEditCopyStaff.vue'
    export default {
        name: 'SystemStaff',
        components: {
            CommonSidebarStaffVue,
            ModalStaffEmploymentVue,
            ModalScreenSettingVue,
            ModalEditCopyStaffVue
        }
    }
</script>

<style lang="scss" scoped>
    .system_staff_style{
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    .main-container {
        display: flex;
        main {
            flex: 1;
            padding: 20px;
            background-color: #f4f7f6;
            height: 100vh;
            overflow-y: auto;
            transition: all .5s ease;
        }
        &.message-list-bg{
            main{
                background-color: #fff0f5;
            }
        }
    }
</style>