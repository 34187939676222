<template>
    <div v-if="isLoading" class="loader-overlay">
      <div class="loader">
        <h1>ローディング</h1>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  
  export default {
    computed: {
      ...mapState('loading', ['isLoading']),
    },
  };
  </script>
  
  <style scoped>
  body {
    background: #c3c1c1;
    background: -webkit-gradient(radial, center center, 120, center center, 900, from(#33495E), to(#2E2329));
    background: -moz-radial-gradient(circle, #33495E, #2E2329);
  }
  
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(141, 141, 143, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loader {
    margin: 200px auto;
  }
  
  h1 {
    font-family: 'Actor', sans-serif;
    color: #FFF;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 200;
    text-align: center;
  }
  
  .loader span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    animation: 3s infinite linear;
  }
  
  .loader span:nth-child(2) {
    background: #E84C3D;
    animation: kiri 1.2s infinite linear;
  }
  
  .loader span:nth-child(3) {
    background: #F1C40F;
    z-index: 100;
  }
  
  .loader span:nth-child(4) {
    background: #2FCC71;
    animation: kanan 1.2s infinite linear;
  }
  
  @keyframes kanan {
    0% {
      transform: translateX(20px);
    }
    50% {
      transform: translateX(-20px);
    }
    100% {
      transform: translateX(20px);
      z-index: 200;
    }
  }
  
  @keyframes kiri {
    0% {
      transform: translateX(-20px);
      z-index: 200;
    }
    50% {
      transform: translateX(20px);
    }
    100% {
      transform: translateX(-20px);
    }
  }
  </style>
  