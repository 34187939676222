<template>
    <div class="system-admin">
      <CommonHeader />
      <div class="main-container">
        <CommonSidebar />
        <main>
          <router-view />
        </main>
      </div>
    </div>
</template>
  
  <script>
  import CommonHeader from '@/components/common/CommonHeader.vue';
  import CommonSidebar from '@/components/common/CommonSidebar.vue';
  
  export default {
    name: 'SystemAdmin',
    components: {
      CommonHeader,
      CommonSidebar,
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .system-admin {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .main-container {
    display: flex;
    main {
      flex: 1;
      padding: 20px;
      background-color: #f4f7f6;
    }
  }
  </style>
  