<template>
    <aside class="sidebar">
      <ul>
        <li v-for="(menu, index) in menus" :key="index">
          <div class="menu-boss" @click="toggleMenu(index)">{{ menu.title }}</div>
          <ul class="submenu" v-show="menu.open">
            <li v-for="(submenu, subIndex) in menu.submenus" :key="subIndex">
              <router-link v-if="!submenu.submenus" :to="submenu.route">{{ submenu.title }}</router-link>
              <div v-else class="colorGreen ml-2" @click="toggleSubmenu(index, subIndex)">{{ submenu.title }}</div>
              <ul class="menuChild" v-if="submenu.submenus" v-show="submenu.open">
                <li v-for="(subsubmenu, subsubIndex) in submenu.submenus" :key="subsubIndex">
                  <router-link :to="subsubmenu.route">{{ subsubmenu.title }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
</template>
  
<script>
  export default {
    name: 'CommonSidebar',
    data() {
      return {
        menus: [
        {
          title: '稼働管理',
          open: true,
          submenus: [
            { title: '売上カレンダー', route: '/system-admin/operation-management/revenue-calendar' },
            { title: 'サイト稼働', route: '/system-admin/operation-management/site-operation' },
            { title: 'ユーザー稼働', route: '/system-admin/operation-management/user-operation' },
            { title: 'キャラ稼働', route: '/system-admin/operation-management/character-operation' },
            { title: 'スタッフ稼働', route: '/system-admin/operation-management/staff-operation' },
            { title: 'ユーザー別消費pt', route: '/system-admin/operation-management/user-consumption-points' },
            { title: '稼働状態', route: '/system-admin/operation-management/operation-status' },
            { title: 'メール配信集計', route: '/system-admin/operation-management/email-distribution-summary' },
            { title: 'SMS配信集計', route: '/system-admin/operation-management/sms-distribution-summary' },
          ],
        },
        {
          title: 'ログ管理',
          open: false,
          submenus: [
            { title: '加減ポイントログ', route: '/system-admin/log-management/point-log' },
            { title: 'メールログ', route: '/system-admin/log-management/email-log' },
            { title: 'サイト内発行ポイント', route: '/system-admin/log-management/issued-points' },
            { title: '一括更新履歴', route: '/system-admin/log-management/bulk-update-history' },
            { title: '一括更新処理状態', route: '/system-admin/log-management/bulk-update-status' },
            { title: '削除待ち一覧（会員・キャラ）', route: '/system-admin/log-management/deletion-list' },
            { title: '削除ログ一覧（自動・手動）', route: '/system-admin/log-management/deletion-log' },
          ],
        },
        {
          title: '会員管理',
          open: false,
          submenus: [
            {
              title: '▼ 会員検索',
              open: false,
              submenus: [
                { title: '検索', route: '/system-admin/member-management/member-search/search' },
                { title: '検索条件ストック', route: '/system-admin/member-management/member-search/search-condition-stock' },
                { title: '約束日時検索', route: '/system-admin/member-management/member-search/promise-date-search' },
              ],
            },
            {
              title: '▼ 会員情報設定',
              open: false,
              submenus: [
                { title: '会員レベル', route: '/system-admin/member-management/member-info-settings/member-level' },
                { title: '会員グループ名設定', route: '/system-admin/member-management/member-info-settings/member-group-name-settings' },
                { title: 'プロフィール', route: '/system-admin/member-management/member-info-settings/profile' },
                { title: '掲示板コメント', route: '/system-admin/member-management/member-info-settings/bulletin-board-comments' },
                { title: '写メール', route: '/system-admin/member-management/member-info-settings/photo-mail' },
                { title: 'ポイント修正', route: '/system-admin/member-management/member-info-settings/point-correction' },
                { title: 'フリーコード編集', route: '/system-admin/member-management/member-info-settings/free-code-edit' },
                { title: 'フリーワード設定', route: '/system-admin/member-management/member-info-settings/free-word-settings' },
                { title: '会員グループ表示順設定', route: '/system-admin/member-management/member-info-settings/member-group-order-settings' },
              ],
            },
            {
              title: '▼ 問合せ',
              open: false,
              submenus: [
                { title: '問合せ管理', route: '/system-admin/member-management/inquiry/inquiry-management' },
                { title: '問合せ拒否管理', route: '/system-admin/member-management/inquiry/inquiry-rejection-management' },
                { title: '問合せ件名設定', route: '/system-admin/member-management/inquiry/inquiry-subject-settings' },
              ],
            },
            {
              title: '▼ 会員削除設定',
              open: false,
              submenus: [
                { title: '会員自動削除設定', route: '/system-admin/member-management/member-deletion-settings/auto-member-deletion-settings' },
                { title: '会員自動削除履歴', route: '/system-admin/member-management/member-deletion-settings/auto-member-deletion-history' },
              ],
            },
            {
              title: '▼ 会員レベル自動変更設定',
              open: false,
              submenus: [
                { title: '会員レベル自動変更管理', route: '/system-admin/member-management/auto-member-level-change-settings/auto-member-level-change-management' },
                { title: '会員レベル自動変更履歴', route: '/system-admin/member-management/auto-member-level-change-settings/auto-member-level-change-history' },
              ],
            },
            {
              title: '▼ 会員グループ自動変更設定',
              open: false,
              submenus: [
                { title: '会員グループ自動変更管理', route: '/system-admin/member-management/auto-member-group-change-settings/auto-member-group-change-management' },
                { title: '会員グループ自動変更履歴', route: '/system-admin/member-management/auto-member-group-change-settings/auto-member-group-change-history' },
              ],
            },
          ],
        },
        {
          title: 'キャラ管理',
          open: false,
          submenus: [
            { title: 'キャラ検索', route: '/system-admin/character-management/character-search' },
            { title: 'ペア検索', route: '/system-admin/character-management/pair-search' },
            { title: 'キャラボックス', route: '/system-admin/character-management/character-box' },
            { title: 'メールボックス', route: '/system-admin/character-management/email-box' },
            { title: 'マルチキャラ詳細地域設定', route: '/system-admin/character-management/multi-character-area-settings' },
            { title: 'ランドマーク編集', route: '/system-admin/character-management/landmark-editing' },
            { title: 'ペアレベル設定', route: '/system-admin/character-management/pair-level-settings' },
            // { title: 'ストックメッセージカテゴリ', route: '/system-admin/character-management/stock-message-category' },
            // { title: 'ペア情報メモタイトル設定', route: '/system-admin/character-management/pair-info-memo-title-settings' },
            // { title: 'ストックメッセージカテゴリ表示順設定', route: '/system-admin/character-management/stock-message-category-order' },
          ],
        },
        // {
        //   title: 'データ移行処理管理',
        //   open: false,
        //   submenus: [
        //     { title: '（標準）ユーザー', route: '/system-admin/data-migration-management/standard-user' },
        //     { title: '（標準）キャラ', route: '/system-admin/data-migration-management/standard-character' },
        //     { title: '（旧仕様）ユーザー', route: '/system-admin/data-migration-management/old-spec-user' },
        //     { title: '（新仕様）ユーザー', route: '/system-admin/data-migration-management/new-spec-user' },
        //     { title: 'データ移行処理履歴', route: '/system-admin/data-migration-management/data-migration-history' },
        //   ],
        // },
        {
          title: '決済管理',
          open: false,
          submenus: [
            { title: '入金管理', route: '/system-admin/payment-management/payment-management' },
            // { title: '入金額パターン設定', route: '/system-admin/payment-management/payment-amount-pattern-settings' },
            { title: '決済表示順設定', route: '/system-admin/payment-management/payment-order-settings' },
            // {
            //   title: '▼ 後払い',
            //   open: false,
            //   submenus: [
            //     { title: '後払管理', route: '/system-admin/payment-management/postpaid/postpaid-management' },
            //     { title: '後払管理履歴', route: '/system-admin/payment-management/postpaid/postpaid-management-history' },
            //   ],
            // },
            {
              title: '▼ 各種決済管理',
              open: false,
              submenus: [
                { title: '決済一覧', route: '/system-admin/payment-management/various-payment-management/payment-list' },
              ],
            },
            {
              title: '▼ 銀行振込',
              open: false,
              submenus: [
                { title: '設定', route: '/system-admin/payment-management/bank-transfer/settings' },
                { title: '履歴', route: '/system-admin/payment-management/bank-transfer/history' },
                // { title: '購入完了メール', route: '/system-admin/payment-management/bank-transfer/purchase-complete-email' },
                // { title: '会員レベル別入金額設定', route: '/system-admin/payment-management/bank-transfer/member-level-based-payment-amount-settings' },
              ],
            },
          ],
        },
        {
          title: '広告管理',
          open: false,
          submenus: [
            { title: '登録集計', route: '/system-admin/ad-management/registration-summary' },
            { title: '売上集計', route: '/system-admin/ad-management/revenue-summary' },
            { title: '代理店管理', route: '/system-admin/ad-management/agency-management' },
            { title: '広告コード管理', route: '/system-admin/ad-management/ad-code-management' },
          ],
        },
        {
          title: 'メール管理',
          open: false,
          submenus: [
            {
              title: '▼ テンプレート管理',
              open: false,
              submenus: [
                { title: 'お知らせメール', route: '/system-admin/email-management/template-management/notification-email' },
                { title: '問合せメール', route: '/system-admin/email-management/template-management/inquiry-email' },
                { title: 'ランダムテキスト', route: '/system-admin/email-management/template-management/random-text' },
              ],
            },
            {
              title: '▼ 配信メール',
              open: false,
              submenus: [
                { title: 'スタッフタイマー送信一覧', route: '/system-admin/email-management/email-distribution/staff-timer-send-list' },
                { title: 'お知らせメール送信履歴', route: '/system-admin/email-management/email-distribution/notification-email-send-history' },
                { title: '同報メール送信履歴', route: '/system-admin/email-management/email-distribution/bulk-email-send-history' },
              ],
            },
            {
              title: '▼ ステップメール',
              open: false,
              submenus: [
                { title: 'お知らせステップメール', route: '/system-admin/email-management/step-email/notification-step-email' },
                { title: 'お知らせステップメール履歴', route: '/system-admin/email-management/step-email/notification-step-email-history' },
                { title: '同報ステップメール', route: '/system-admin/email-management/step-email/bulk-step-email' },
                { title: '同報ステップメール履歴', route: '/system-admin/email-management/step-email/bulk-step-email-history' },
                { title: 'ステップメールグループ', route: '/system-admin/email-management/step-email/step-email-group' },
              ],
            },
            {
              title: '▼ スケジュールメール',
              open: false,
              submenus: [
                { title: 'お知らせスケジュールメール', route: '/system-admin/email-management/scheduled-email/notification-scheduled-email' },
                { title: 'お知らせスケジュールメール履歴', route: '/system-admin/email-management/scheduled-email/notification-scheduled-email-history' },
                { title: '同報スケジュールメール', route: '/system-admin/email-management/scheduled-email/bulk-scheduled-email' },
                { title: '同報スケジュールメール履歴', route: '/system-admin/email-management/scheduled-email/bulk-scheduled-email-history' },
                { title: 'スケジュールメールグループ', route: '/system-admin/email-management/scheduled-email/scheduled-email-group' },
              ],
            },
            {
              title: '▼ 新着お知らせメール',
              open: false,
              submenus: [
                { title: '新着お知らせメール', route: '/system-admin/email-management/new-arrival-notification-email/index' },
                { title: '通常用設定', route: '/system-admin/email-management/new-arrival-notification-email/config' },
                { title: 'スタッフ用設定', route: '/system-admin/email-management/new-arrival-notification-email/config-staff' },
                { title: '仮登録用設定', route: '/system-admin/email-management/new-arrival-notification-email/config-pre' },
              ],
            },
            {
              title: '▼ リメール管理',
              open: false,
              submenus: [
                { title: '仮登録完了メール', route: '/system-admin/email-management/remail-management/pre-registration-completion-email' },
                { title: '本登録完了メール', route: '/system-admin/email-management/remail-management/registration-completion-email' },
                { title: '退会メール', route: '/system-admin/email-management/remail-management/withdrawal-email' },
                { title: '振込先情報メール', route: '/system-admin/email-management/remail-management/bank-information-email' },
                { title: '不正宛先自動返信メール', route: '/system-admin/email-management/remail-management/invalid-destination-auto-reply-email' },
                { title: '購入完了メール', route: '/system-admin/email-management/remail-management/purchase-completion-email' },
                { title: 'パスワードリマインダメール', route: '/system-admin/email-management/remail-management/password-reminder-email' },
                { title: '画像登録ポイント不足メール', route: '/system-admin/email-management/remail-management/insufficient-points-image-registration-email' },
                { title: 'PCアドレス登録不可メール', route: '/system-admin/email-management/remail-management/pc-address-registration-unavailable-email' },
                { title: '退会済メール', route: '/system-admin/email-management/remail-management/withdrawn-email' },
                { title: '登録済メール', route: '/system-admin/email-management/remail-management/registered-email' },
                { title: '画像登録エラーメール', route: '/system-admin/email-management/remail-management/image-registration-error-email' },
              ],
            },
            {
              title: '▼ SMSお知らせメール',
              open: false,
              submenus: [
                { title: '新着お知らせメール', route: '/system-admin/email-management/sms-notification-email/index' },
                { title: 'お知らせメール設定', route: '/system-admin/email-management/sms-notification-email/config' },
              ],
            },
            {
              title: '▼ 自動返信メール',
              open: false,
              submenus: [
                { title: 'お知らせメール管理', route: '/system-admin/email-management/auto-reply-email/notification-email-management' },
                { title: 'お知らせメール履歴', route: '/system-admin/email-management/auto-reply-email/notification-email-history' },
                { title: '同報メール管理', route: '/system-admin/email-management/auto-reply-email/bulk-email-management' },
                { title: '同報メール履歴', route: '/system-admin/email-management/auto-reply-email/bulk-email-history' },
              ],
            },
          ],
        },
        {
          title: 'ページ管理',
          open: false,
          submenus: [
            {
              title: '▼ 標準ページ管理',
              open: false,
              submenus: [
                { title: 'TOPページ', route: '/system-admin/page-management/standard-page-management/top-page' },
                { title: '利用規約ページ', route: '/system-admin/page-management/standard-page-management/terms-of-service-page' },
                { title: '運営者情報（特定商取引法に基づく表記）', route: '/system-admin/page-management/standard-page-management/operator-information-page' },
                { title: 'プライバシーポリシー', route: '/system-admin/page-management/standard-page-management/privacy-policy-page' },
                { title: '振込先情報', route: '/system-admin/page-management/standard-page-management/bank-information-page' },
                { title: 'ポイント追加ページ', route: '/system-admin/page-management/standard-page-management/points-addition-page' },
                { title: 'メール受信設定ページ', route: '/system-admin/page-management/standard-page-management/mail-refusal-settings-page' },
                { title: '後払クッションページ', route: '/system-admin/page-management/standard-page-management/post-payment-buffer-page' },
                { title: '退会ページ', route: '/system-admin/page-management/standard-page-management/withdrawal-page' },
                { title: 'メール送信完了ページ', route: '/system-admin/page-management/standard-page-management/mail-completion-page' },
                { title: 'プレゼント完了ページ', route: '/system-admin/page-management/standard-page-management/present-completion-page' },
                { title: '後払い設定ページ', route: '/system-admin/page-management/standard-page-management/post-payment-settings-page' },
                { title: 'ポイントについて(会員)', route: '/system-admin/page-management/standard-page-management/points-about-member-page' },
                { title: 'ポイントについて(非会員)', route: '/system-admin/page-management/standard-page-management/points-about-non-member-page' },
              ],
            },
            {
              title: '▼ 登録ページ管理',
              open: false,
              submenus: [
                { title: '仮登録フォーム（先空メ）', route: '/system-admin/page-management/registration-page-management/temporary-registration-form-before' },
                { title: '仮登録フォーム（後空メ）', route: '/system-admin/page-management/registration-page-management/temporary-registration-form-after' },
                { title: 'ヘッダー文言（仮登録）', route: '/system-admin/page-management/registration-page-management/temporary-registration-header' },
                { title: 'ヘッダー文言（本登録）', route: '/system-admin/page-management/registration-page-management/final-registration-header' },
                { title: 'API登録完了文言', route: '/system-admin/page-management/registration-page-management/api-registration-completion-text' },
              ],
            },
            {
              title: '▼ Myページ管理',
              open: false,
              submenus: [
                { title: 'ログイントップページ作成', route: '/system-admin/page-management/my-page-management/login-top-page-creation' },
                { title: 'ログイントップページ設定', route: '/system-admin/page-management/my-page-management/login-top-page-settings' },
                { title: 'ログイントップページ自動更新設定', route: '/system-admin/page-management/my-page-management/login-top-page-auto-update-settings' },
              ],
            },
            {
              title: '▼ アピールページ',
              open: false,
              submenus: [
                { title: '会員レベル別ページ設定', route: '/system-admin/page-management/appeal-page/member-level-specific-page-settings' },
                { title: 'ページデザイン作成', route: '/system-admin/page-management/appeal-page/page-design-creation' },
              ],
            },
            {
              title: '▼ 個別ページ管理',
              open: false,
              submenus: [
                { title: '会員用個別ページ', route: '/system-admin/page-management/individual-page-management/member-individual-page' },
                { title: '非会員用個別ページ', route: '/system-admin/page-management/individual-page-management/non-member-individual-page' },
                { title: '個別ページグループ', route: '/system-admin/page-management/individual-page-management/individual-page-group' },
              ],
            },
            {
              title: '▼ デザイン管理',
              open: false,
              submenus: [
                { title: 'SP版用CSS設定', route: '/system-admin/page-management/design-management/sp-css-settings' },
              ],
            },
            {
              title: '▼ 画像管理',
              open: false,
              submenus: [
                { title: '画像倉庫', route: '/system-admin/page-management/image-management/image-warehouse' },
                { title: '画像グループ', route: '/system-admin/page-management/image-management/image-group' },
              ],
            },
          ],
        },
        {
          title: 'ポイント管理',
          open: false,
          submenus: [
            { title: '加算ポイント設定', route: '/system-admin/point-management/add-points-settings' },
            { title: '消化ポイント設定', route: '/system-admin/point-management/consume-points-settings' },
            { title: '特別消化ポイント設定', route: '/system-admin/point-management/special-consume-points-settings' },
            { title: 'NGワード設定', route: '/system-admin/point-management/ng-word-settings' },
          ],
        },
        {
          title: 'サイト管理',
          open: false,
          submenus: [
            {
              title: '▼ サイト設定',
              open: false,
              submenus: [
                { title: 'アカウント設定', route: '/system-admin/site-management/account-settings' },
                { title: 'スタッフ管理', route: '/system-admin/staff-management/staff-management' },
                // { title: 'アカウント設定', route: '/system-admin/site-management/site-settings' },
                // { title: 'スタッフ管理', route: '/system-admin/site-management/account-settings' },
                { title: 'システム設定', route: '/system-admin/system-settings/system-settings' },
                { title: 'アクセス制限設定', route: '/system-admin/access-restriction-settings/access-restriction-settings' },
              ],
            },
            {
              title: '▼ コンバート管理',
              open: false,
              submenus: [
                { title: '送信設定（タイマー専用）', route: '/system-admin/system-settings/convert-management/send-setting' },
                { title: '送信履歴／受信履歴', route: '/system-admin/system-settings/convert-management/send-history' },
              ],
            },
            {
              title: '▼ リレー管理',
              open: false,
              submenus: [
                { title: 'リレー設定', route: '/system-admin/relay-management/relay-settings' },
              ],
            },
            {
              title: '▼ メール送信監視',
              open: false,
              submenus: [
                { title: '配信チェック（wbサーバー）', route: '/system-admin/relay-management/mail-send-monitoring' },
              ],
            },
            {
              title: '▼ システムドメイン管理',
              open: false,
              submenus: [
                { title: '本文内ドメイン管理', route: '/system-admin/system-domain-management/domain-management-in-text' },
                { title: 'Fromドメイン管理', route: '/system-admin/system-domain-management/from-domain-management' },
                { title: 'ドメインチェック', route: '/system-admin/system-domain-management/domain-check' },
              ],
            },
            {
              title: '▼ アフィリエイト管理',
              open: false,
              submenus: [
                { title: 'アフィリエイト設定', route: '/system-admin/affiliate-management/affiliate-settings' },
                { title: 'アフィリエイト履歴', route: '/system-admin/affiliate-management/affiliate-history' },
              ],
            },
            {
              title: '▼ 拒否管理',
              open: false,
              submenus: [
                { title: '登録拒否アドレス設定', route: '/system-admin/rejection-management/registration-rejection-address-settings' },
                { title: '登録拒否電話番号設定', route: '/system-admin/rejection-management/registration-rejection-phone-number-settings' },
                { title: 'リメール拒否アドレス設定', route: '/system-admin/rejection-management/remail-rejection-address-settings' },
                { title: 'リメール拒否電話番号設定', route: '/system-admin/rejection-management/remail-rejection-phone-number-settings' },
              ],
            },
            {
              title: '▼ メールキュー管理',
              open: false,
              submenus: [
                { title: 'メールキュー', route: '/system-admin/mail-queue-management/mail-queue' },
              ],
            },
            {
              title: '▼ 独自API機能',
              open: false,
              submenus: [
                { title: 'IPアドレス登録', route: '/system-admin/custom-api-function/ip-address-registration' },
              ],
            },
          ],
        },
      ],
      };
    },
    methods: {
      toggleMenu(index) {
        this.menus[index].open = !this.menus[index].open;
      },
      toggleSubmenu(index, subIndex) {
        this.menus[index].submenus[subIndex].open = !this.menus[index].submenus[subIndex].open;
      },
    },
  };
</script>
  
<style lang="scss" scoped>
  .colorGreen{
    color: #006400 !important;
    cursor: pointer;
    transition: color 0.3s;
    &:hover{
      opacity: 0.8;
    }
  }
  .sidebar {
    width: fit-content;
    min-width: fit-content;
    background-color: #eee3bc;
    color: #333;
    padding: 0px 10px;
    height: 100vh;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    position: sticky;
    top: 0;

    .menu-boss {
      font-weight: bold;
      padding: 5px 0;
      cursor: pointer;
      transition: color 0.3s;
    }
    .menuChild{
      margin-left: 10px;
    }
    .menu-boss:hover {
      opacity: 0.8;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    li {
      padding: 2px 0;
    }
    li a {
      color: black;
      font-size: 13px;
      margin-left: 10px
    }
    .router-link-active {
      font-weight: bold;
      text-decoration: underline;
      opacity: 0.8;
    }
  }
</style>
  