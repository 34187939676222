const systemStaffRoutes = [
    {
        path: 'list-character-box',
        component: () => import('@/components/systemStaff/Character/ListCharacterBox.vue'),
        // children: [
        //     { path: 'list-character-box-index', component: () => import('@/components/systemStaff/ListCharaterBox/ListCharaterBoxIndex.vue') },
        // ],
    },
    {
        path: 'character-edit',
        component: () => import('@/components/systemStaff/Character/CharacterEdit.vue'),
    },
    {
        path: 'message-list',
        component: () => import('@/components/systemStaff/Character/MessageList.vue'),
    },
];
  
export default systemStaffRoutes;