const state = {
    alertMessage: '',
    alertVisible: false
  };
  
  const mutations = {
    setAlert(state, message) {
      state.alertMessage = message;
      state.alertVisible = true;
    },
    clearAlert(state) {
      state.alertMessage = '';
      state.alertVisible = false;
    }
  };
  
  const actions = {
    showAlert({ commit }, message) {
      commit('setAlert', message);
      setTimeout(() => {
        commit('clearAlert');
      }, 3000); // Hide after 3 seconds
    }
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  };
  