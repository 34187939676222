import axios from 'axios';
import router from '../router'; // Ensure this path is correct based on your project structure
import store from '../store'; // Import the store

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Add a request interceptor to include the token in all requests and show loading
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    store.dispatch('loading/showLoading'); // Show loading
    return config;
  },
  error => {
    store.dispatch('loading/hideLoading'); // Hide loading on error
    return Promise.reject(error);
  }
);

// Add a response interceptor to check for authentication errors and hide loading
instance.interceptors.response.use(
  response => {
    store.dispatch('loading/hideLoading'); // Hide loading
    return response;
  },
  error => {
    store.dispatch('loading/hideLoading'); // Hide loading
    if (error.response && error.response.status === 401) {
      // Remove the token from localStorage
      localStorage.removeItem('authToken');
      // Redirect to the login page
      router.push('/');
    }
    return Promise.reject(error);
  }
);

export default instance;
