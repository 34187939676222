<template>
    <aside class="sidebar_staff">
        <div class="logo mb-2">
            <h1>Romance</h1>
        </div>
        <div class="profile">
            <h2>スタッフ用</h2>
            <div class="avatar">
                <h2>ID: enzi</h2>
                <img src="../../../assets/images/user.svg" alt="">
            </div>
        </div>
        <p class="text-right text-xs">(システム確認用)</p>
        <div class="version_in_use mb-3">
            <h2 class="text-[#dc143c] font-bold">＜詳細版利用中＞</h2>
            <ul>
                <li><router-link to="/system-staff/character-edit">キャラ登録</router-link></li>
                <li><a data-toggle="modal" data-target="#modalStaffEmployment" class="cursor-pointer">スタッフ稼働</a></li>
                <li><router-link to="/system-staff/list-character-box">キャラBOX</router-link></li>
                <li><a data-toggle="modal" data-target="#modalSreenSetting" class="cursor-pointer">設定画面</a></li>
                <li><a href="#">ログアウト</a></li>
            </ul>
        </div>
        <div class="character_selection mb-3">
            <p class="title_bg mb-2">キャラ選択</p>
            <div class="box_desecription mb-2">
                <div class="item">
                    <p class="title">性別</p>
                    <div class="content">
                        <div class="gendar">
                            <button :class="{ active: selectedGender === 'female' }" @click="selectGender('female')">女性</button>
                            <button :class="{ active: selectedGender === 'male' }" @click="selectGender('male')">男性</button>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <p class="title">BoxNo</p>
                    <div class="content">
                        <select  multiple="multiple" size="5" class="px-0 mx-0 w-full">
                            <option value="-1">すべて</option>
                            <option value="1">掲示板キャラ</option>
                            <option value="2">■自動返信キャラ</option>
                            <option value="3">★ステップキャラ</option>
                            <option value="4">★誘導キャラ</option>
                            <option value="5">☆停止誘導キャラ</option>
                            <option value="6">イベント潰しキャラ</option>
                            <option value="7">イベント煽りキャラ</option>
                            <option value="8">金イベントキャラ</option>
                        </select>
                    </div>
                </div>
                <div class="item">
                    <p class="title">地域</p>
                    <div class="content">
                        <select class="ml-0" name="data[Search][CharConfig][area_type]">
                            <option value="-1">すべて</option>
                            <option value="1">地域</option>
                            <option value="2">マルチ</option>
                        </select>
                    </div>
                </div>
            </div>
            <button>この条件でキャラを表示</button>
        </div>
        <div class="list_character ">
            <p class="title_bg mb-2">キャラ一覧</p>
            <div class="flex flex-col items-baseline">
                <select class="ml-0 mb-2 w-full" name="data[Search][read_type]" id="read_unread_select">
                    <option id="unread_select" value="1">未読すべて(7)</option>
                    <option id="read_select" value="2">既読すべて(9,279)</option>
                </select>
                <div class="flex items-center w-full justify-between mb-2">
                    <p>表示件数:</p>
                    <select name="perPage" class="mx-0">
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="300">300</option>
                        <option value="400">400</option>
                        <option value="500">500</option>
                        <option value="600">600</option>
                        <option value="700">700</option>
                        <option value="800">800</option>
                        <option value="900">900</option>
                        <option value="1000">1000</option>
                        <option value="1500">1500</option>
                        <option value="2000">2000</option>
                    </select>
                </div>
                <button class="mb-2">メール一覧を表示 >></button>
                <ul class="note mb-2">
                    <li class="text-xs flex items-start"><img src="../../../assets/images/flag.png" alt=""> …3日以内に同報履歴アリ</li>
                    <li class="text-xs flex items-start"><img src="../../../assets/images/icon_noprint.png" alt=""> …掲示板非表示</li>
                </ul>
                <ul class="filter_mail">
                    <li><img src="../../../assets/images/unread.png" alt=""><a href="#">未読順</a></li>
                    <li><img src="../../../assets/images/open-email.png" alt=""><a href="#">既読順</a></li>
                    <li><a href="#">登録順</a></li>
                </ul>
            </div>
        </div>
        <div class="listMail">
            <div class="item" v-for="(item, index) in 10" :key="index">
                <p><router-link to="/system-staff/message-list">みわ</router-link>  33才 ★誘導キャラ マルチ</p>
                <ul>
                    <li><router-link to="/system-staff/message-list"><img src="../../../assets/images/unread.png" alt=""> 2</router-link></li>
                    <li><router-link to="/system-staff/message-list"><img src="../../../assets/images/open-email.png" alt=""> 837</router-link></li>
                    <li><a data-toggle="modal" data-target="#modalEditCopyStaff" href="#">編集・複製</a></li>
                    <li><a href="#">EX</a></li>
                </ul>
            </div>
        </div>
    </aside>
</template>

<script>
    export default {
        components: {
            
        },
        data() {
            return {
                selectedGender: ''
            };
        },
        methods: {
            selectGender(gender) {
                this.selectedGender = gender;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sidebar_staff{
        width: 290px;
        background: #fff0f5;
        height: 100dvh;
        overflow-y: auto;
        padding: 10px 10px 0;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
        font-size: 15px;
       
        .logo{
            h1{
                font-size: 18px;
                font-weight: bold;
            }
        }
        .profile{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .avatar{
                display: flex;
                align-items: center;
                gap: 5px;
                >img{
                    width: 24px;
                }
            }
        }
        .version_in_use{
            h2{
                margin-bottom: 10px;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                font-size: 13px;
                li{
                    a{
                        text-decoration: underline;
                    }
                    &:not(:first-child){
                        border-left: 1px solid #242424;
                        margin-left: 5px;
                        padding-left: 5px;
                    }
                }
            }
        }
        .box_desecription{
            .item{
                border: 1px solid #666;
                border-bottom: 0;
                display: flex;
                p.title{
                    padding: 0.3em 0.5em;
                    border-right: 1px solid #666;
                    background: #eee;
                    width: 56px;
                }
                .content{
                    flex: 1;
                    background: #fff;
                    padding: 0.3em 0.5em;
                    .gendar{
                        button{
                            background: #fff;
                            color: #323a49;
                            &.active{
                                background: #323a49;
                                color: #fff;
                            }
                        }
                    }
                    
                }
                &:last-child{
                    border-bottom: 1px solid #666;
                }
            }
        }
        .list_character{
            .note{
                display: flex;
                li{
                    img{
                        width: 16px;
                    }
                }
            }
            .filter_mail{
                display: flex;
                width: 100%;
                border-bottom: 1px solid #666;
                position: relative;
                padding-bottom: 5px;
                &::after{
                    content: '';
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    bottom: -3px;
                    background: #666;
                }
                li{
                    display: flex;
                    align-items: center;
                    padding-right: 5px;
                    margin-right: 5px;
                    border-right: 1px solid #666;
                    &:last-child{
                        border-right: 0;
                    }
                }
            }
        }
        .listMail{
            padding-bottom: 20px;
            .item{
                border-bottom: 1px solid #666;
                padding: 10px 0;
                p{
                    a{
                        text-decoration: none;
                        border-bottom: 1px dashed #666;
                        display: inline-block;
                        &:hover{
                            border-bottom: 1px solid #000;
                        }
                    }
                }
                ul{
                    display: flex;
                    justify-content: space-between;
                    
                    li{
                        a{
                            display: flex;
                            align-items: center;
                            border-bottom: 1px dashed #666;
                            text-decoration: none;
                            &:hover{
                                border-bottom: 1px solid #000;
                            }
                        }
                    }
                }
            }
        }
    }
</style>