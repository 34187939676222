<template>
    <div class="dateFromTo" :class="!hidePeriod ? 'mb-3' : 'mb-0'">
        <label class="mr-2" v-if="!hideTitle">期間:</label>
        <select v-if="!hidePeriod" v-model="period">
            <option>今日</option>
            <option>昨日</option>
            <option>今週</option>
            <option>先週</option>
            <option>今月</option>
            <option>先月</option>
        </select>
        <svg class="mr-2" :class="!hidePeriod ? 'ml-2' : ''" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
        </svg>
        <div class="flex items-center mr-2" v-if="!flgYear">
            <select v-model="yearStart">
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
            </select>
            <label>年</label>
        </div>
        <div class="flex items-center mr-2" v-if="!flgMonth">
            <select v-model="monthStart">
                <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
            </select>
            <label>月</label>
        </div>
        <div class="flex items-center mr-2" v-if="!flgDay">
            <select v-model="dayStart">
                <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
            </select>
            <label>日{{!flgHour || !flgEndTimme ? '' : '～'}}</label>
        </div>
        <div class="flex items-center mr-2" v-if="!flgHour">
            <select v-model="startHour">
                <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
            </select>
            <label>時 {{!flgMinute ? '' : '～'}}</label>
        </div>
        <div class="flex items-center mr-2" v-if="!flgMinute">
            <select v-model="startMinute">
                <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
            </select>
            <label>分{{!flgEndTime ? '～' : ''}}</label>
        </div>
        <div class="endTime flex items-center" v-if="!flgEndTime">
            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
            </svg>
            <div class="flex items-center mr-2" v-if="!flgYear">
                <select v-model="yearEnd">
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>
                <label>年</label>
            </div>
            <div class="flex items-center mr-2" v-if="!flgMonth">
                <select v-model="monthEnd">
                    <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
                </select>
                <label>月</label>
            </div>
            <div class="flex items-center mr-2" v-if="!flgDay">
                <select v-model="dayEnd">
                    <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                </select>
                <label>日</label>
            </div>
            <div class="flex items-center mr-2" v-if="!flgHour">
                <select v-model="endHour">
                    <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
                </select>
                <label>時</label>
            </div>
            <div class="flex items-center mr-2" v-if="!flgMinute">
                <select v-model="endMinute">
                    <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
                </select>
                <label>分</label>
            </div>
        </div>
      
        <button v-if="!flgBtnSearch" @click="displayData">表示</button>

    </div>
</template>

<script>
export default {
    name: 'DateFromTo',
    props: {
        flgYear: {
            type: Boolean,
        },
        flgMonth: {
            type: Boolean,
        },
        flgDay: {
            type: Boolean,
        },
        flgHour: {
            type: Boolean,
        },
        flgMinute: {
            type: Boolean,
        },
        flgEndTime: {
            type: Boolean,
        },
        flgEndMinute: {
            type: Boolean,
        },
        hidePeriod: {
            type: Boolean,
        },
        
        flgBtnSearch: {
            type: Boolean
        },
        hideTitle: {
            type: Boolean
        }
    },
    data() {
        return {
            period: '今日',
            years: [2024, 2023, 2022],
            months: Array.from({ length: 12 }, (_, i) => i + 1),
            days: Array.from({ length: 31 }, (_, i) => i + 1),
            yearStart: 2024,
            monthStart: 7,
            dayStart: 1,
            yearEnd: 2024,
            monthEnd: 7,
            dayEnd: 1,
            startHour: 0,
            startMinute: 0,
            endHour: 10,
            endMinute: 31,
            hours: Array.from({ length: 24 }, (_, i) => i),
            minutes: Array.from({ length: 60 }, (_, i) => i)
        }
    },
    methods: {
        displayData() {
            console.log('Display data for:', this.yearStart, this.monthStart, this.dayStart, 'to', this.yearEnd, this.monthEnd, this.dayEnd);
        }
    }
};
</script>

<style lang="scss" scoped>
   
    .dateFromTo{
        display: flex;
        align-items: center;
        select{
            margin-left: 0;
            width: 58px;
            padding: 2px;
        }
        label{
            white-space: nowrap;
        }
        svg{
            width: 30px;
        }
        button{
            margin-left: 10px;
        }
    }
    .date-time-group{
        select{
            width: fit-content;
            padding: 2px 5px;
            margin: 0px 2px;
        }
    }
</style>
  