<template>
    <div class="adcode_style">
      <CommonHeader />
        <main>
            <router-view></router-view>
        </main>
    </div>
</template>

<script>
    import CommonHeader from '@/components/common/CommonHeader.vue';
    export default {
        name: 'AdcodeLayout',
        components: {
            CommonHeader,
        },
    }
</script>

<style lang="scss" scoped>
    .adcode_style{
        min-height: calc(100vh - 46px);
        main{
            padding: 20px;
            background-color: #f4f7f6;
            height: 100%;
        }
    }
</style>