<template>
    <div class="modal fade" id="modalEditCopyStaff" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
                <div class="modal-body">
                    <div class="scrollBox">
                        <p class="title_bg mb-2">キャラ編集</p>
                        <p class="mb-2">※キャラ複製は、詳細内の <span class="text-[#f00]">「掲示板表示」を【非表示】</span> に変更することでボタンが表示されます</p>
                        <div class="flex items-center mb-3">
                            <button>確認画面へ</button>
                            <button class="btnDel">キャラ削除</button>
                        </div>
                        <div class="table_form w-[99%] mb-3">
                            <div class="item">
                                <p class="titleBox">性別</p>
                                <div class="form_content pl-2">
                                    <div class="radio-group">
                                        <label>
                                            <input class="ml-0" type="radio" value="1">
                                            <span>男性</span>
                                        </label>
                                        <label>
                                            <input checked type="radio" value="2" >
                                            <span>女性</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">マルチ / 地域</p>
                                <div class="form_content pl-2">
                                    <div class="radio-group">
                                        <label>
                                            <input class="ml-0" type="radio" value="1" >
                                            <span>地域</span>
                                        </label>
                                        <label>
                                            <input checked type="radio" value="2" >
                                            <span>マルチ</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">ニックネーム</p>
                                <div class="form_content flex-col pl-2">
                                    <input value="竹内 文乃" class="ml-0 w-[200px]" type="text" >
                                    <p class="colorOrange pl-0">※1~50文字まで</p>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">年齢</p>
                                <div class="form_content">
                                    <input value="52" type="text" class="w-16">才
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">マルチキャラ詳細エリア</p>
                                <div class="form_content ">
                                    <select>
                                        <option value="1">詳細地域1</option>
                                        <option value="2">詳細地域2</option>
                                        <option value="3">詳細地域3</option>
                                        <option value="4">詳細地域4</option>
                                        <option value="5">詳細地域5</option>
                                        <option value="9">ナイショ</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item" >
                                <p class="titleBox">地域キャラ詳細エリア</p>
                                <div class="form_content ">
                                    <select disabled>
                                        <option >1</option>
                                    </select>⇒
                                    <select disabled>
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">キャラボックス</p>
                                <div class="form_content ">
                                    <select value="1">
                                        <option value="1">掲示板キャラ</option>
                                        <option value="2">■自動返信キャラ</option>
                                        <option value="3">★ステップキャラ</option>
                                        <option value="4">★誘導キャラ</option>
                                        <option value="5">☆停止誘導キャラ</option>
                                        <option value="6">イベント潰しキャラ</option>
                                        <option value="7">イベント煽りキャラ</option>
                                        <option value="8">金イベントキャラ</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">掲示板表示</p>
                                <div class="form_content pl-2">
                                    <div class="radio-group">
                                        <label>
                                            <input class="ml-0" type="radio" value="1" >
                                            <span>表示</span>
                                        </label>
                                        <label>
                                            <input type="radio" value="0">
                                            <span>非表示</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">PCメールアドレス</p>
                                <div class="form_content pr-2 items-center">
                                    <input type="text" class="width24em">
                                    <span class="text-[#f00] text-sm">※機能との連動はありません</span>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">携帯メールアドレス</p>
                                <div class="form_content pr-2 items-center">
                                    <input type="text" class="width24em">
                                    <span class="text-sm" style="color:#f00;">※機能との連動はありません</span>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">キャラ固有コード</p>
                                <div class="form_content pl-2">
                                    <span class="text-sm">9JBoyWLF4vHctVzniaAd</span>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">無料フラグ</p>
                                <div class="form_content pl-2">
                                    <div class="radio-group">
                                        <label>
                                            <input class="ml-0" type="radio" value="1" >
                                            <span>有効</span>
                                        </label>
                                        <label>
                                            <input checked type="radio" value="0" >
                                            <span>無効</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">職種</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">ステータス</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">趣味</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">年収</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">性格</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">好み</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">休み・都合の良い時間</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">目的</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">未設定</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">永久無料項目</p>
                                <div class="form_content ">
                                    <select >
                                        <option >1</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">ステップメールの設定の有無</p>
                                <div class="form_content ">
                                    <p class="text-[#888]">設定なし</p>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">コメント</p>
                                <div class="form_content flex-col pl-2">
                                    <textarea style="height:6em" class="ml-0"></textarea>
                                    <span class="colorOrange text-sm font-normal">※1～1000文字（半角/全角ともに1文字）&nbsp;</span>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">アピールページ用コメント</p>
                                <div class="form_content flex-col pl-2">
                                    <textarea style="height:6em" class="ml-0"></textarea>
                                    <span class="colorOrange text-sm font-normal">※1000文字以下（半角/全角ともに1文字）&nbsp;</span>
                                    <div class="errorCell"></div>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">画像登録</p>
                                <div class="form_content items-baseline pt-2 pl-2">
                                    <label>現在の画像：</label>
                                    <img src="../../../assets/images/noimage.jpg" alt="No Image" width="100" height="100">
                                    <label class="ml-3 font-normal">画像変更:</label>
                                    <input class="ml-0" type="file" >
                                    <button type="button" class="btnDel">画像削除</button>
                                </div>
                            </div>
                            <div class="item">
                                <p class="titleBox">キャラメモ</p>
                                <div class="form_content flex-col ">
                                    <textarea class="width100Per"></textarea>
                                </div>
                            </div> 
                        </div>
                        <div class="flex items-center">
                            <button>確認画面へ</button>
                            <button class="btnDel">キャラ削除</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    #modalEditCopyStaff{
        .scrollBox{
            max-height: 90vh;
            overflow: auto;
        }
        
    }
</style>