<template>
  <section class="wrapper">
    <div class="content login_form_style">
      <header>
        <h1>運営者管理画面ログイン</h1>
      </header>
      <section>
        <form @submit.prevent="login" class="login-form">
          <div class="input-group">
            <label for="username">ログインID</label>
            <input type="text" v-model="username" placeholder="ログインID" id="username">
            <span v-if="errors.username" class="error">{{ errors.username[0] }}</span>
          </div>
          <div class="input-group">
            <label for="password">パスワード</label>
            <input type="password" v-model="password" placeholder="パスワード" id="password">
            <span v-if="errors.password" class="error">{{ errors.password[0] }}</span>
          </div>
          <div class="input-group items-center">
            <button class="flex items-center justify-center" type="submit">ログイン</button>
          </div>
          <div v-if="errors.general" class="general-error">{{ errors.general }}</div>
        </form>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
      errors: {}
    };
  },
  methods: {
    async login() {
      this.errors = {}; // Reset errors
      try {
        const response = await this.$axios.post('/login', {
          username: this.username,
          password: this.password
        });
        localStorage.setItem('authToken', response.data.token); // Save the token in local storage
        this.$axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`; // Set the token for future requests
        this.$router.push('/system-admin/operation-management/revenue-calendar');
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors;
        } else if (error.response && error.response.status === 401) {
          this.errors.general = 'ユーザー名またはパスワードが間違っています。';
        } else {
          console.error('Error logging in:', error);
          this.errors.general = 'サーバーエラーが発生しました。しばらくしてから再試行してください。';
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito');

$background-start: #5433FF;
$background-end: #20BDFF;

$gray-color: #eee;
$input-color: #e4eff8;
$label-color: #999;
$login-button: #20BDFF;
$error-color: #ff4d4d;

body {
  font-family: 'Nunito', sans-serif;
  background: linear-gradient(to right, $background-start, $background-end);
  margin: 0;
  height: 100vh;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    background-color: #fff;
    padding: 30px 15px;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    min-width: 200px;
    &.login_form_style{
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 20px;
    }
    button {
      padding: 15px;
      width: 150px;
      border: 1px solid $gray-color;
      background: transparent;
      border-radius: 50px;
      color: #333;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    header {
      text-align: center;
      h1 {
        margin-top: 0;
        font-size: 180%;
      }
    }
    .social-login {
      padding: 10px;
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background .5s;       
        -webkit-transition: background .5s;
        &:first-child {
          margin-right: 10px;
        }
        &:hover {
          background-color: $gray-color;
        }
        span {
          font-size: 17px;
          color: #777;
          margin-left: 5px;
        }
      }
    }
    .login-form {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .input-group {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 100%;
        max-width: 310px;
        input {
          padding: 15px;
          font-size: 13px;
          border: 1px solid $input-color;
          background-color: $input-color;
          border-radius: 50px;
          transition: border .5s;
          -webkit-transition: border .5s;
          &:focus {
            outline: 0;
            border-color: $background-end;
          }
        }
        label {
          color: $label-color;
          font-size: 12px;
          margin-bottom: 3px;
          margin-left: 16px;
        }
        .error {
          color: $error-color;
          font-size: 12px;
          margin-top: 5px;
          margin-left: 16px;
        }
        button {
          padding: 15px;
          width: 150px;
          border: 1px solid $login-button;
          background-color: $login-button;
          border-radius: 50px;
          color: #fff;
          cursor: pointer;
          margin-top: 15px;
          transition: background .5s;
          -webkit-transition: background .5s;
          &:hover {
            background-color: $background-start;
          }
        }
      }
      .general-error {
        color: $error-color;
        font-size: 14px;
        margin-top: 15px;
      }
    }
    footer {
      padding-top: 15px;
      text-align: center;
      a {
        color: #999;
        text-decoration: none;
        font-size: 11px;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .wrapper {
    .content {
      padding-right: 0;
      padding-left: 0;
      margin: 0 10px;
    }
  }
}
</style>
