const state = {
    isLoading: false,
  };
  
  const mutations = {
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  };
  
  const actions = {
    showLoading({ commit }) {
      commit('setLoading', true);
    },
    hideLoading({ commit }) {
      commit('setLoading', false);
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };
  