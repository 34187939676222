<template>
    <div class="modal fade" id="modalStaffEmployment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
                <div class="modal-body">
                    <p class="title_bg">スタッフ稼働</p>
                    <div class="box_filter mb-3">
                        <DateFromToVue 
                            flgHour="true"
                            flgMinute="true"
                            hidePeriod="true"

                        />
                    </div>
                    <ResultSearchDataVue totalResult="1"/>
                    <button class="mb-3">更新</button>
                    <div class="table-container mb-3">
                        <table class="table-fix table-bordered">
                            <thead>
                                <tr class="bg-green text-center text-nowrap">
                                    <th rowspan="2">ID</th>
                                    <th rowspan="2">氏名</th>
                                    <th colspan="2">未読メール送信数</th>
                                    <th colspan="2">既読メール送信数</th>
                                    <th colspan="2">会員検索メール送信数</th>
                                    <th rowspan="2">メール開封数</th>
                                    <th rowspan="2">メール受信数</th>
                                    <th rowspan="2">消費Pt</th>
                                </tr>
                                <tr class="text-center">
                                    <th>個別</th>
                                    <th>一斉</th>
                                    <th>個別</th>
                                    <th>一斉</th>
                                    <th>個別</th>
                                    <th>一斉</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-nowrap backSilver" v-for="(item, index) in 30" :key="index">
                                    <td class="text-[#2D952D]"><router-link to="#">66</router-link></td>
                                    <td>tobi</td>
                                    <td class="text-right">0</td>
                                    <td class="text-right">0</td>
                                    <td class="text-right">0</td>
                                    <td class="text-right">0</td>
                                    <td class="text-right">0</td>
                                    <td class="text-right">0</td>
                                    <td class="text-right">1</td>
                                    <td class="text-right">1</td>
                                    <td class="text-right">0</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="bgLightgreen text-right text-nowrap">
                                    <td colspan="2" class="text-left">スタッフ計</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>0</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <button>閉じる</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DateFromToVue from '../systemAdmin/Operation/DateFromTo.vue'
    import ResultSearchDataVue from '../systemAdmin/Operation/ResultSearchData.vue'
    export default {
        components: {
            DateFromToVue,
            ResultSearchDataVue
        }
        
    }
</script>

<style lang="scss" scoped>
    #modalStaffEmployment{
        .table-container{
            max-height: 60vh;
            overflow: auto;
        }
    }
</style>