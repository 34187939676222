<template>
    <div class="modal fade" id="modalSreenSetting" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
                <div class="modal-body">
                    <div class="scrollBox">
                        <p class="title_bg">スタッフ作業初期設定画面</p>
                        <div class="box_config mb-3">
                            <p class="font-bold mb-2">共通デフォルト値</p>
                            <div class="table-container">
                                <table class="table-fix table-bordered">
                                    <thead>
                                        <tr class="bg-green text-center">
                                            <th>項目名</th>
                                            <th>内容</th>
                                            <th>説明</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="backSilver">
                                            <td>送信時刻までに相手キャラにメールを送信したユーザーは除くチェックON/OFF</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    ON
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    OFF
                                                </label>
                                            </td>
                                            <td class="">デフォルトのチェックをON/OFFか選択できます</td>
                                        </tr>
                                        <tr class="backSilver">
                                            <td>メール送信タイマー規定値 0:00 ON/OFF <span class="text-[#f50]">※</span>OFFの場合は現在時刻/OFF</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    ON
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    OFF
                                                </label>
                                            </td>
                                            <td class="">デフォルトのチェックをON/OFFか選択できます</td>
                                        </tr>
                                        <tr class="backSilver">
                                            <td>配信種別の規定値 ON/OFF <span class="text-[#f50]">※</span>ONの場合は登録メールアドレス＋電話番号宛てにSMSへの通知送信</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    ON
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    OFF
                                                </label>
                                            </td>
                                            <td class="">デフォルトのチェックをON/OFFか選択できます</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="box_config mb-3">
                            <p class="font-bold mb-2">詳細版・標準版デフォルト値</p>
                            <div class="table-container">
                                <table class="table-full table-bordered">
                                    <thead>
                                        <tr class="bg-green text-center">
                                            <th>項目名</th>
                                            <th>内容</th>
                                            <th>説明</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="backSilver">
                                            <td>スタッフ画面バージョン切替</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    詳細版
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    標準版
                                                </label>
                                            </td>
                                            <td class="">スタッフ画面を別タイプに切り替えます</td>
                                        </tr>
                                        <tr class="backSilver">
                                            <td>キャラ性別デフォルト値</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    男性
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    女性
                                                </label>
                                            </td>
                                            <td class="">スタッフ画面の初期検索性別を男性に切り替えます</td>
                                        </tr>
                                        <tr class="backSilver">
                                            <td>既読・未読デフォルト値</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    既読
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    未読
                                                </label>
                                            </td>
                                            <td class="">スタッフ画面の初回検索を既読・未読のどちらかに切り替えます</td>
                                        </tr>
                                        <tr class="backSilver">
                                            <td>検索結果表示件数デフォルト値</td>
                                            <td >
                                                <select class="ml-0" id="search_limit">
                                                    <option value="10">10</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="200">200</option>
                                                    <option value="300">300</option>
                                                    <option value="400">400</option>
                                                    <option value="500">500</option>
                                                    <option value="600">600</option>
                                                    <option value="700">700</option>
                                                    <option value="800">800</option>
                                                    <option value="900">900</option>
                                                    <option value="1000">1000</option>
                                                    <option value="1500">1500</option>
                                                    <option value="2000">2000</option>
                                                </select>
                                            </td>
                                            <td class="">検索結果の表示件数のデフォルト値を設定します</td>
                                        </tr>
                                        <tr class="backSilver">
                                            <td>メール履歴表示デフォルト値</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1">
                                                    <input class="mx-0" type="radio" value="1">
                                                    すべて
                                                </label>
                                                <label class="font-normal flex items-center gap-1">
                                                    <input class="mx-0" type="radio" checked="">
                                                    受信のみ
                                                </label>
                                                <label class="font-normal flex items-center gap-1">
                                                    <input class="mx-0" type="radio" >
                                                    送信のみ
                                                </label>
                                            </td>
                                            <td class="">デフォルトのチェックをON/OFFか選択できます</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="box_config mb-3">
                            <p class="font-bold mb-2">EXデフォルト値</p>
                            <div class="table-container">
                                <table class="table-fix table-bordered">
                                    <thead>
                                        <tr class="bg-green text-center">
                                            <th>項目名</th>
                                            <th>内容</th>
                                            <th>説明</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="backSilver">
                                            <td>送信確認画面使用有無</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    使用する
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    使用しない
                                                </label>
                                            </td>
                                            <td class="">送信の際確認画面が出ます</td>
                                        </tr>
                                        <tr class="backSilver">
                                            <td>送信完了後次のユーザーに自動的に移動有無(既読ユーザーのみ)</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    使用する
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    使用しない
                                                </label>
                                            </td>
                                            <td class="">送信後自動的に次のユーザーにフォームが選択されます</td>
                                        </tr>
                                        <tr class="backSilver">
                                            <td>タイマー送信の形式</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    カレンダー
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    今から○分後
                                                </label>
                                            </td>
                                            <td class="">カレンダー形式か、今から○分後形式のどちらかを選択できます</td>
                                        </tr>
                                        <tr class="backSilver">
                                            <td>メール履歴表示デフォルト値</td>
                                            <td class="timer_exclude_parent">
                                                <label class="font-normal flex items-center mr-3 gap-1" id="radio_group_timer_exclude___1">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___1" value="1">
                                                    すべて
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    受信のみ
                                                </label>
                                                <label class="font-normal flex items-center gap-1" id="radio_group_timer_exclude___0">
                                                    <input class="mx-0" type="radio" id="radio_group_timer_exclude___0" checked="">
                                                    送信のみ
                                                </label>
                                            </td>
                                            <td class="">メール送受信履歴の表示デフォルト値を設定します</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="flex items-center mb-3">
                            <button>設定を反映させる</button>
                            <button>閉じる</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    #modalSreenSetting{
        .scrollBox{
            max-height: 90vh;
            overflow: auto;
        }
        .table-container{
            width: 100%;
            table{
                min-width: none;
                width: 100%;
            }
        }
    }
</style>