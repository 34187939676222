<template>
  <header class="header">
    <div class="logo">
      <h1>romance</h1>
    </div>
    <div class="user-info">
      <span>LoginAccountName: エンジ</span>
      <a href="#" class="btn" @click.prevent="goToDashboard">管理画面TOP</a>
      <a href="#" class="btn" @click.prevent="logout">ログアウト</a>
    </div>
  </header>
</template>

<script>
export default {
  name: 'CommonHeader',
  methods: {
    goToDashboard() {
      this.$router.push('/system-admin/operation-management/revenue-calendar');
    },
    async logout() {
      const token = localStorage.getItem('authToken');
      if (!token) {
        this.$router.push('/');
        return;
      }

      try {
        await this.$axios.post('/logout');
        localStorage.removeItem('authToken');
        this.$router.push('/');
      } catch (error) {
        console.error('Error logging out:', error);
        alert('ログアウトに失敗しました。');
      }
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.logo h1 {
  margin: 0;
  font-size: 30px;
}
.user-info {
  display: flex;
  align-items: center;
  font-size: 17px;
}
.user-info span {
  margin-right: 20px;
}
.user-info .btn {
  margin-left: 10px;
  padding: 5px 10px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 3px;
  transition: background-color 0.3s;
}
.user-info .btn:hover {
  background-color: #0056b3;
}
</style>
