<template>
    <div class="result_data_style">
        <p>件数：<span>{{totalResult}}件</span>（{{currentPage}}/{{totalPages}}ページ） </p>
    </div>
</template>

<script>
export default {
    name: 'ResultSearchData',
    props: {
        totalResult: {
            type: Number
        },
        currentPage: {
            type: Number
        },
        totalPages: {
            type: Number
        }
    },
};
</script>

<style lang="scss" scoped>
    .result_data_style{
        background-image: linear-gradient(180deg, #f0f0f0, #e1e1e1);
        padding: 10px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        p{
            font-size: 14px;
            font-weight: bold;
            span{
                color: #e3153b;
            }
        }
    }
</style>
  