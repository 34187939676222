const agencyRoutes = [
    {
        path: '',
        component: () => import('@/components/agency/AgencyIndex.vue'),
    },
    {
        path: 'list-user-registed',
        component: () => import('@/components/agency/ListUserRegisted.vue'),
    },
    
];
  
export default agencyRoutes;