<template>
  <div id="app">
    <LoadingOverlay />
    <router-view />
  </div>
</template>

<script>
import LoadingOverlay from './components/LoadingOverlay.vue';

export default {
  name: 'App',
  components: {
    LoadingOverlay,
  },
};
</script>

<style scoped>
#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>

<style>
body {
  margin: 0px;
}
</style>
